import React from 'react';
import { Button } from 'antd';
import { useNavigate } from "react-router";
const LandingPage = () => {
    let navigate = useNavigate();
    const chuyenHuongTrang = () => {
        console.log('click')
        navigate('/register')
    }
    return (
        <div>
            <div className='bg-[#C50300] h-[600px] py-5'>

                    <div className='container mx-auto bg-black bg-opacity-30 rounded-3xl px-10'>
                        <div className='flex h-16 items-center justify-between text-xl'>
                            <ul className='flex items-center gap-5'>
                                <li className='cursor-pointer text-white hover:text-[#FFFB6E]'>Trang chủ</li>
                                <li className='cursor-pointer text-white hover:text-[#FFFB6E]'>Thể lệ</li>
                                <li className='cursor-pointer text-white hover:text-[#FFFB6E]'>Hỗ trợ</li>
                            </ul>
                            <div className='cursor-pointer text-white text-xl hover:text-[#FFFB6E]' onClick={chuyenHuongTrang}>
                                Đăng ký tham gia    
                            </div>
                    </div>
                </div>
                <div className='container mx-auto bg-black bg-opacity-30 rounded-3xl mt-10 h-auto p-5'>
                <div className='text-[#FFFB6E] text-3xl font-bold text-center'>
                    <h1>ỦY BAN NHÂN DÂN TỈNH NAM ĐỊNH</h1>
                    <h1>ỦY BAN NHÂN DÂN HUYỆN NAM TRỰC</h1>
                </div>
                <div className='flex justify-center mt-10'>
                    <img className='w-[200px] h-[200px]' src='https://cdn.haiphong.gov.vn/gov-hpg/upload/kienan/product/2023/07-2023/Logo-Bo-phan-tiep-nhan-va-Tra-ket-qua-ve-125447-638262176342874035.png'/>
                </div>
                <div className='text-[#FFFB6E] text-3xl font-bold text-center mt-10'>
                    <h3>CUỘC THI TRỰC TUYẾN</h3>
                    <h3>"TÌM HIỂU VỀ CÔNG TÁC CẢI CÁCH HÀNH CHÍNH NĂM" 2024</h3>
                </div>
                </div>         
            </div>
            <div className='bg-[#930000] h-16 text-white flex items-center justify-center font-bold text-2xl'>
                <h6>CUỘC THI TÌM HIỂU TRỰC TUYẾN “TÌM HIỂU CÔNG TÁC CẢI CÁCH HÀNH CHÍNH, CHUYỂN ĐỔI SỐ” NĂM 2024</h6>
            </div>
            <div className='py-40'>
                <div className='container mx-auto text-[#930000] text-2xl font-bold'>
                    <h1 className='text-center'>CUỘC THI BẮT ĐẦU VÀO</h1>
                </div>
            </div>
            <div className='footer'>
                <div className='bg-[#C50300] text-white h-52 flex items-center justify-center'>
                    <div className='flex gap-36 items-center container mx-auto justify-center'>
                        <div>
                            <p>BAN TỔ CHỨC</p>
                            <p>ỦY BAN NHÂN DÂN HUYỆN NAM TRỰC</p>
                            <p>Địa chỉ: Thị trấn Nam Giang - Huyện Nam Trực - Tỉnh Nam Định</p>
                            <p>Số điện thoại: (0228)3827051</p>
                            <p>Email: ubndhuyennamtruc-namdinh@chinhphu.vn</p>
                        </div>
                        <div>
                            <p>ĐƠN VỊ TRIỂN KHAI, PHÁT TRIỂN</p>
                            <p>Văn phòng HĐND và UBND huyện Nam Trực</p>
                            <p>Địa chỉ: Thị trấn Nam Giang - Huyện Nam Trực - Tỉnh Nam Định</p>
                            <p>Số điện thoại: (0228)3827051</p>
                            <p>Email: ubndhuyennamtruc-namdinh@chinhphu.vn</p>
                        </div>
                    </div>            
                </div>
                
                <div className='bg-[#930000] text-white h-10 flex items-center justify-center'>
                Copyright © Văn phòng HĐND và UBND huyện Nam Trực 2024. Designed and developed by Văn phòng HĐND và UBND huyện Nam Trực
                </div>
            </div>
        </div>
    );
};

export default LandingPage;