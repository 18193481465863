import React from 'react';
import { Form, Input, Button, message } from 'antd';

const RegistrationPage = () => {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        // Placeholder for API call
        message.success('Đăng ký thành công!');
        console.log(values)
        // onRegister(values);
    };

    return (
        <div className='w-full h-[1000px] flex items-center justify-center bg-[url("https://img7.thuthuatphanmem.vn/uploads/2023/09/16/background-chuyen-doi-cong-nghe-so-full-hd-dep-nhat_091026308.jpg")]'>
            <div>
                <div className='container mx-auto w-[500px] bg-white px-10 py-10 rounded-3xl'>
                    <h2 className='text-center text-2xl font-bold mb-2'>ĐĂNG KÝ THAM GIA CUỘC THI</h2>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} className='flex flex-col'>
                        <Form.Item name="name" label="Họ tên" rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="phone" label="Số điện thoại" rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="cccd" label="Số CCCD" rules={[{ required: true, message: 'Vui lòng nhập số CCCD' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="address" label="Địa chỉ" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="job" label="Nghề nghiệp">
                            <Input />
                        </Form.Item>
                        <Form.Item name="organization" label="Đơn vị công tác">
                            <Input />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" >
                            Đăng ký
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;