import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';

const fakeQuestions = [
    { id: 1, question: 'Câu 1: Nội dung cải cách nào quan trọng nhất?', options: ['Tổ chức Bộ máy', 'Xây dựng Chính quyền', 'Cải cách thể chế', 'Chuyển đổi xanh'] },
    { id: 2, question: 'Câu 2: Luật nào liên quan đến cải cách hành chính?', options: ['A', 'B', 'C', 'D'] },
    { id: 3, question: 'Câu 3: Mục tiêu cải cách hành chính là gì?', options: ['A', 'B', 'C', 'D'] }
];

const QuizPage = () => {
    const [questions, setQuestions] = useState(fakeQuestions);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes in seconds
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false)
    useEffect(() => {
        const savedStartTime = localStorage.getItem('quizStartTime');
        const now = Date.now();
        let remainingTime = 30 * 60; // 30 minutes in seconds

        if (savedStartTime) {
            const elapsed = Math.floor((now - parseInt(savedStartTime)) / 1000); // thời gian đã trôi qua
            remainingTime = Math.max(30 * 60 - elapsed, 0); // đảm bảo không âm
        } else {
            localStorage.setItem('quizStartTime', now.toString());
        }

        setTimeLeft(remainingTime);

        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleAnswerSelect = (answer) => {
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].answer = answer;
        setQuestions(updatedQuestions);
    };

    const handleQuestionSelect = (index) => {
        setCurrentQuestionIndex(index);
    };

    const handleSubmit = () => {
        setSubmitModalVisible(true);
    };

    const handleConfirmSubmit = () => {
        console.log('Submitted answers:', questions);
        setSubmitModalVisible(false);
        localStorage.removeItem('quizStartTime'); //
    };
      const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };
      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <div style={{ width: '20%', paddingRight: '20px' }}>
                    <h3>Danh sách câu hỏi</h3>
                    {questions.map((q, index) => (
                        <Button
                            key={q.id}
                            onClick={() => handleQuestionSelect(index)}
                            style={{
                                display: 'block',
                                margin: '10px 0',
                                backgroundColor: q.answer ? 'lightgreen' : 'white'
                            }}
                        >
                            Câu {index + 1}
                        </Button>
                    ))}
                </div>
                <div style={{ flex: 1 }}>
                    <h2>{questions[currentQuestionIndex].question}</h2>
                    <div>
                        {questions[currentQuestionIndex].options.map((option, index) => (
                            <Button
                                key={index}
                                onClick={() => handleAnswerSelect(option)}
                                style={{
                                    display: 'block',
                                    margin: '10px 0',
                                    backgroundColor: questions[currentQuestionIndex].answer === option ? 'lightgreen' : 'white'
                                }}
                            >
                                {option}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
               <div>
                <Button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
                    Câu trước
                </Button>
                <Button onClick={handleNext} disabled={currentQuestionIndex === questions.length - 1}>
                    Câu tiếp theo
                </Button>
            </div>
            <p>Thời gian còn lại: {formatTime(timeLeft)}</p>
            <div style={{ marginTop: '20px' }}>
                <Button type="primary" onClick={handleSubmit}>
                    Nộp bài
                </Button>
            </div>

            <Modal
                visible={isSubmitModalVisible}
                title="Xác nhận nộp bài"
                onCancel={() => setSubmitModalVisible(false)}
                onOk={handleConfirmSubmit}
            >
                Bạn có chắc chắn muốn nộp bài không?
            </Modal>
        </div>
    );
};

export default QuizPage;